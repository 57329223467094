import React, { useEffect, useState } from 'react';

import { isMobile } from 'react-device-detect';
import { Modal } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { ReactComponent as PneumoAILogo } from '../../res/Pneumo_AI_Logo_weiss.svg';

import fontStyles from '../text/Text.module.css';
import styles from './MobileModal.module.css';
import { s } from '../../app/helper';

const MobileModal: React.FC = () => {
  const [t] = useTranslation('common');

  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = (): void => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobileWidth = width <= 768;

  if (isMobile || isMobileWidth) {
    return (
      <Modal show centered className={styles.modal}>
        <Modal.Body className={styles.modalBody}>
          <PneumoAILogo className={styles.detailsLogo} />
          <div className={s(fontStyles.SubHeaderPages, styles.mobileText)}>{t('mobile-text')}</div>
          <div className={s(fontStyles.text, styles.mobileText)}>{t('mobile-text2')}</div>
        </Modal.Body>
      </Modal>
    );
  }

  return null;
};

export default MobileModal;
