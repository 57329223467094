import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import styles from './GameDetail.module.css';
import { s } from '../../app/helper';
import fontStyles from '../text/Text.module.css';
/**
 * interface of Game Detail
 */
interface GameDetailProps {
  src: string,
  userVote: boolean
  predictionSpecialist: boolean,
  predictionSpecialistAI: boolean,
  annotation: boolean,
  sampleIndex: number
}

const GameDetail = ({
  src, userVote, predictionSpecialist, predictionSpecialistAI, annotation, sampleIndex,
}: GameDetailProps): JSX.Element => {
  const [t] = useTranslation('common');
  return (
    <div className={styles.cardOuterStyle}>
      <Card className={styles.gameCard}>
        <Card.Body className={styles.gameCardBody}>
          <div className={s(styles.finalScore, fontStyles.text)}>
            <div>{`${t('bild')} ${sampleIndex + 1}`}</div>
            <div>
              <FontAwesomeIcon
                icon={faCircle}
                className={`${styles.bullet} ${annotation ? styles.badColor : styles.goodColor}`}
              />
              &nbsp;&nbsp;
              <span>{annotation ? t('pneumonia') : t('normal')}</span>
            </div>
          </div>
          <div className={styles.imageFrame}>
            <Image
              src={src}
              data-testid="main-image"
              className={`${styles.mainImage} ${annotation ? styles.badBorder : styles.goodBorder} `}
            />
          </div>
          <div className={s(styles.prediction, fontStyles.text)}>
            <FontAwesomeIcon
              icon={faCircle}
              className={`${styles.bullet} ${userVote ? styles.badColor : styles.goodColor}`}
            />
            &nbsp;&nbsp;
            {userVote ? t('pneumonia') : t('normal')}
          </div>
          <div className={s(styles.prediction, fontStyles.text)}>
            <FontAwesomeIcon
              icon={faCircle}
              className={`${styles.bullet} ${predictionSpecialist ? styles.badColor : styles.goodColor}`}
            />
            &nbsp;&nbsp;
            {predictionSpecialist ? t('pneumonia') : t('normal')}
          </div>
          <div className={s(styles.prediction, fontStyles.text)}>
            <FontAwesomeIcon
              icon={faCircle}
              className={`${styles.bullet} ${predictionSpecialistAI ? styles.badColor : styles.goodColor}`}
            />
            &nbsp;&nbsp;
            {predictionSpecialistAI ? t('pneumonia') : t('normal')}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
export default GameDetail;
