import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Image, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
// removed only temporarily
// import { ReactComponent as Info } from '../../res/Info.svg';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { getUser } from '../../reducer/userReducer';

import FooterNav from '../../features/footerNav/FooterNav';
import MobileModal from '../../features/mobileModal/MobileModal';
import LangToggle from '../../features/langToggle/LangToggle';

import ki_nrw_logo from '../../res/ki-nrw-logo.svg';

import { ReactComponent as PneumoAILogo } from '../../res/Pneumo_AI_Logo_weiss.svg';

import DC_lp from '../../res/DC_lp.svg';

import styles from './LandingLoginPage.module.css';
import fontStyles from '../../features/text/Text.module.css';

/**
 * render landingLoginPage component
 */
const LandingLoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [t] = useTranslation('landing');
  const [commonT] = useTranslation('common');
  const dispatch = useAppDispatch();
  const userStatus = useAppSelector((state) => state.user.status);

  /* triggers user update */
  useEffect(() => {
    dispatch(getUser());
  }, []);

  const buttonBar: () => JSX.Element = () => {
    if (userStatus === 'progress' || userStatus === 'verify') {
      return (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      );
    }
    if (userStatus === 'loggedIn') {
      return (
        <>
          <Button
            variant="light"
            className={styles.loginButton}
            onClick={() => {
              navigate('/introduction');
              window.location.reload();
            }}
          >
            {commonT('continue')}
          </Button>
          <Button
            variant="outline-light"
            className={styles.registerButton}
            onClick={() => {
              navigate('/oidc/logout');
              window.location.reload();
            }}
          >
            {commonT('log-out')}
          </Button>
        </>
      );
    }
    return (
      <>
        <Button
          variant="light"
          className={styles.loginButton}
          onClick={() => {
            navigate('/oidc/authenticate');
            window.location.reload();
          }}
        >
          {commonT('log-in')}
        </Button>
        <Button
          variant="outline-light"
          className={styles.registerButton}
          onClick={() => {
            navigate('/oidc/authenticate');
            window.location.reload();
          }}
        >
          {commonT('register')}
        </Button>
      </>
    );
  };

  return (
    <div className={styles.body}>
      <div className={styles.bodyTop}>
        <div className={styles.contents}>
          <PneumoAILogo className={styles.detailsLogo} />
          {/* <div className={`${styles.detailsHeader} ${fontStyles.HeaderPages}`}> */}
          {/*  {t('title')} */}
          {/* </div> */}
          <div className={`${styles.detailsSubHeader} ${fontStyles.SubHeaderPages}`}>
            {t('subtitle')}
          </div>
          <div className={`${styles.detailsText} ${fontStyles.text}`}>
            {t('description')}
          </div>
          {
            // removed info button temporarily
            // <div className={`${fontStyles.info} ${styles.moreInfo} `}>
            // <Info />
            //  &nbsp;&nbsp;
            //  {t('more-information')}
            // </div>
          }
          <div className={styles.langToggle}>
            <LangToggle center={false} dropdown={false} />
          </div>
        </div>
        <Image src={DC_lp} className={styles.mainImage} />
      </div>
      <div className={styles.buttonBar}>
        {buttonBar()}
      </div>
      <div className={styles.footerLogo}>
        <a href="https://www.ki.nrw/">
          <Image
            src={ki_nrw_logo}
            className={styles.poweredByLogo}
          />
        </a>
      </div>
      <FooterNav />
      <MobileModal />
    </div>
  );
};

export default LandingLoginPage;
