import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import Card from 'react-bootstrap/Card';
import GameDetail from '../gameDetail/GameDetail';
import { ButtonPurpleOnWhite } from '../customButtons/CustomButtons';
import styles from './GameScoreBoard.module.css';
import fontStyles from '../text/Text.module.css';

import { s } from '../../app/helper';

/**
 * interface of Game Score Board
 */
interface GameScoreBoardProps {
  listOfImages: string[],
  predictionsSpecialistAI: boolean[],
  predictionsSpecialist: boolean[],
  annotations: boolean[],
  userVotes: (boolean | null)[]
}

const GameScoreBoard = ({
  listOfImages, predictionsSpecialistAI, predictionsSpecialist, annotations, userVotes,
}: GameScoreBoardProps): JSX.Element => {
  const indexFirstImageInPage = 0;
  const imagesPerPage = 5;
  const navigate = useNavigate();
  const [t] = useTranslation('game_score_board');
  const [commonT] = useTranslation('common');

  /**
   * To get the carousel, we slice the images list according to current image index and total images we want to display.
   */
  const imagesInCurrentPage = listOfImages.slice(indexFirstImageInPage, indexFirstImageInPage + imagesPerPage);

  const rowHeader = (
    <div className={styles.titleCardOuterStyle}>
      <Card
        className={styles.titleCard}
      >
        <Card.Body className={styles.titleCardBody}>
          <div className={s(styles.titlePlaceHolder, fontStyles.text)}>
            &nbsp;
          </div>
          <div className={styles.imageDivPlaceholder} />
          <div className={s(styles.predictionLabel, fontStyles.text)}>
            {commonT('your-choice')}
          </div>
          <div className={s(styles.predictionLabel, fontStyles.text)}>
            {commonT('specialist')}
          </div>
          <div className={s(styles.predictionLabel, fontStyles.text)}>
            {commonT('specialist-ai')}
          </div>
        </Card.Body>
      </Card>
    </div>
  );

  return (
    <>
      <Row className={styles.row} key="1">
        <Col xs={12} lg={8} className={styles.captionBar}>
          <p className={fontStyles.HeaderPages}>{t('header-text')}</p>
          <p className={fontStyles.text}>{t('subheader-text')}</p>
        </Col>
        <Col xs={12} lg={4} className={styles.rightButtonBar}>
          <ButtonPurpleOnWhite
            className={styles.button}
            onClick={() => {
              navigate('/explain');
            }}
          >
            {t('more-information')}
          </ButtonPurpleOnWhite>
          <ButtonPurpleOnWhite
            className={styles.button}
            onClick={() => {
              navigate('/play');
            }}
          >
            <FontAwesomeIcon icon={faRotateRight} />
            &nbsp;&nbsp;
            {t('start-new-game')}
          </ButtonPurpleOnWhite>
        </Col>
      </Row>
      <Row className={styles.row} key="2">
        {imagesInCurrentPage.map((fs, inx) => {
          const imgIndex = listOfImages.indexOf(fs);
          let headerClass = '';
          if (inx !== 0) {
            if (inx % 2 === 0) {
              // show on md and smaller
              headerClass = 'd-block d-lg-none';
            } else {
              // show on sm and smaller
              headerClass = 'd-block d-md-none';
            }
          }

          return (
            <>
              <Col
                xs={6}
                md={4}
                lg={2}
                className={s(headerClass, styles.resultColumn)}
              >
                <span data-testid={inx} />
                {rowHeader}
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className={styles.resultColumn}
              >
                <GameDetail
                  key={`img-${imgIndex}`}
                  src={fs}
                  userVote={userVotes[imgIndex] as boolean}
                  predictionSpecialistAI={predictionsSpecialistAI[imgIndex] as boolean}
                  predictionSpecialist={predictionsSpecialist[imgIndex] as boolean}
                  annotation={annotations[imgIndex] as boolean}
                  sampleIndex={inx}
                />
              </Col>
            </>
          );
        })}
      </Row>
    </>
  );
};
export default GameScoreBoard;
