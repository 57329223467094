import { APIError } from '../app/helper';
import { logOut, needsRefresh } from './userReducer';

export const handleAuthError = (error: APIError, thunkAPI: any): void => {
  if (error.status === 401) {
    thunkAPI.dispatch(logOut());
  }

  const redirected = (error.details.status > 300 && error.details.status < 400)
    || error.details.type === 'opaqueredirect';

  if (!error.details || redirected || (error.details.redirected
    && error.details.headers.get('Content-Type')?.includes('html'))) {
    thunkAPI.dispatch(needsRefresh());
  }
  error.handled = true;
};
