import React from 'react';
import styles from './LoadAnimation.module.css';
import { ReactComponent as LoadingAni } from '../../res/LoadingAni.svg';

const GameAnimation = (): JSX.Element => (
  <div className={styles.loadingGame}>
    <LoadingAni className={styles.loadIcon} />
  </div>
);
export default GameAnimation;
