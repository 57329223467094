import React/* , { useRef } */ from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Image, Row } from 'react-bootstrap';

import demonstrator_example_v1 from '../../res/demonstrator_example_v1.png';
import demonstrator_example_v2 from '../../res/demonstrator_example_v2.png';
import demonstrator_example_v3 from '../../res/demonstrator_example_v3.png';
import demonstrator_example_v4 from '../../res/demonstrator_example_v4.png';
import { s } from '../../app/helper';

import styles from './ExplainCarousel.module.css';
import fontStyles from '../text/Text.module.css';

type ExplainSlideProps = {
  slideImage: string;
  slideTitle: string;
  slideDescription: string;
};

const formatParagraphs = (textContent: string): JSX.Element[] => (
  textContent.split('\n').map((l, inx) => (
    <span
      // we know the textContent will always contain sorted paragraphs so we dont really care about
      // this rule.
      // eslint-disable-next-line react/no-array-index-key
      key={`${inx}`}
      className={styles.descriptionParagraph}
    >
      {l}
    </span>
  ))
);

const ExplainSlide: React.FC<ExplainSlideProps> = (
  {
    slideImage,
    slideTitle,
    slideDescription,
  },
) => (
  <>
    <Col
      xs={3}
      xxl={2}
      xxxl={1}
      className={s(styles.explainSlide)}
    >
      <Image src={slideImage} className={styles.explainSlideImage} />
    </Col>
    <Col
      xs={9}
      xxl={4}
      xxxl={3}
      className={s(styles.explainSlide)}
    >
      <div className={styles.explainSlideDetails}>
        <div className={s(styles.explainSlideTitle, fontStyles.SubHeaderPages)}>
          {slideTitle}
        </div>
        <div className={s(styles.explainSlideDescription, fontStyles.text)}>
          {formatParagraphs(slideDescription)}
        </div>
      </div>
    </Col>
  </>
);

const ExplainCarousel: React.FC = () => {
  const [t] = useTranslation('explain');

  return (
    <Row
      className={styles.explainCarouselContainer}
    >
      <ExplainSlide
        slideImage={demonstrator_example_v1}
        slideTitle={`${t('patient')} 1`}
        slideDescription={t('explanation-patient-1')}
      />
      <ExplainSlide
        slideImage={demonstrator_example_v2}
        slideTitle={`${t('patient')} 2`}
        slideDescription={t('explanation-patient-2')}
      />
      <ExplainSlide
        slideImage={demonstrator_example_v3}
        slideTitle={`${t('patient')} 3`}
        slideDescription={t('explanation-patient-3')}
      />
      <ExplainSlide
        slideImage={demonstrator_example_v4}
        slideTitle={`${t('patient')} 4`}
        slideDescription={t('explanation-patient-4')}
      />
    </Row>
  );
};

export default ExplainCarousel;
