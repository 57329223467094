import {
  configureStore, ThunkAction, Action,
} from '@reduxjs/toolkit';
import gameReducer from '../reducer/gameReducer';
import gameListReducer from '../reducer/gameListReducer';
import userReducer from '../reducer/userReducer';

export const store = configureStore({
  reducer: {
    game: gameReducer,
    gameList: gameListReducer,
    user: userReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
RootState,
unknown,
Action<string>>;
