import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useParams } from 'react-router-dom';
import styles from '../css/pages/MainScreen.module.css';
import LoadAnimation from '../features/loadAnimation/LoadAnimation';
import GameController from '../features/gameController/GameController';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getGame } from '../reducer/gameReducer';

/**
 * render main screen component
 */
const GameScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const game = useAppSelector((state) => state.game.game);
  const status = useAppSelector((state) => state.game.status);
  const { gameId } = useParams();

  useEffect(() => {
    if (gameId) {
      dispatch(getGame({ game_id: gameId }));
    }
  }, [gameId, dispatch]);

  return (
    <Container className={styles.mainContainer} fluid>
      {status === 'loading' ? <LoadAnimation /> : <GameController game={game} />}
    </Container>
  );
};

export default GameScreen;
