import React, { useEffect, useRef, useState } from 'react';
import GameScoreBoard from '../gameScoreboard/GameScoreBoard';
import LoadAnimation from '../loadAnimation/LoadAnimation';
import GameMetricsDashboard from './GameMetricsDashboard';
import GamePlayer from '../gamePlayer/GamePlayer';
import { NestedGame } from '../../app/datatypes';
import { replacer, reviver } from '../../app/helper';
import styles from './GameController.module.css';

/**
 * interface of Game Controller
 * interface of Game Controller
 */
interface GameControllerProps {
  game: NestedGame,

}

/**
 * render GameController
 * @param game
 */

const GameController: React.FC<GameControllerProps> = ({
  game,
}: GameControllerProps) => {
  const listOfImages = game?.samples?.map((s) => s.img) || [];
  const predictionsSpecialistAI = game?.samples?.map((s) => s.pred_specialist_ai);
  const predictionsSpecialist = game?.samples?.map((s) => s.pred_specialist);
  const annotations = game?.samples?.map((s) => s.annotation);
  const lengthListImage = listOfImages.length;

  const intervalRef = useRef<NodeJS.Timer>();

  /**
   * generates a game data structure without any vote
   */
  function generateEmptyVotes(): Map<number, boolean | null> {
    const defaultUserVotes: Map<number, boolean | null> = new Map();
    for (let i = 0; i <= lengthListImage - 1; i += 1) {
      defaultUserVotes.set(i, null);
    }
    return defaultUserVotes;
  }

  function loadVotes(): Map<number, boolean | null> {
    // loads votes from local session storage
    const voteData = localStorage.getItem(game.id);
    if (voteData) {
      const votes = JSON.parse(voteData, reviver);

      return votes.votes as Map<number, boolean | null>;
    }
    return generateEmptyVotes();
  }

  function loadTime(): number {
    // loads total time taken from local session storage
    const timeData = localStorage.getItem(`${game.id}-time`);

    if (timeData && !Number.isNaN(timeData)) {
      return Number(timeData);
    }
    return 0;
  }

  const [userVotes, setUserVotes] = useState(loadVotes());
  const [time, setTime] = useState(loadTime());
  const isGameFinished = Array.from(userVotes.keys()).every((e) => typeof userVotes.get(e) === 'boolean');

  // wether or not the clock should start counting or not. Triggered by
  // gamePlayer when the game becomes visible to the user
  const [clockStarted, setClockStarted] = useState<boolean>(false);

  // if true game was played in this since this component was loaded.
  // if game is finished and was played in the same session we will show loading bar and overlay
  const [gamePlayedNow, setGamePlayedNow] = useState(false);

  const [showLoadingBarResults, setShowLoadingBarResults] = useState(false);
  const [showMetricOverlay, setShowMetricOverlay] = useState(false);

  useEffect(() => {
    // if game was finished we at first show a loading animation and then proceed to show the results
    if (isGameFinished && gamePlayedNow) {
      clearInterval(intervalRef.current);
      setShowLoadingBarResults(true);
      setTimeout(() => {
        setShowMetricOverlay(true);
        setShowLoadingBarResults(false);
      }, 2000);
    }
  }, [isGameFinished]);

  useEffect(() => {
    setUserVotes(loadVotes());
  }, [game]);

  useEffect(() => {
    if (!isGameFinished && clockStarted) {
      intervalRef.current = setInterval(() => {
        setTime((ct) => {
          localStorage.setItem(`${game.id}-time`, `${ct + 1}`);
          return ct + 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [clockStarted]);

  /**
   * update user votes on GameVoter component
   * @param userVotesInput is map which contains the user votes.
   */

  const updateUserVotes = (userVotesInput: Map<number, boolean | null>): void => {
    setUserVotes(userVotesInput);
    setGamePlayedNow(true);
    localStorage.setItem(game.id, JSON.stringify({ votes: userVotesInput }, replacer));
    localStorage.setItem(`${game.id}-time`, `${time}`);
  };

  if (lengthListImage === 0) {
    return <div data-testid="no-list-images">keine Bilder</div>;
  }

  // if true should show an overlay on top of the game itself
  const showOverlayOnGame = showLoadingBarResults || showMetricOverlay;

  const overlay = showOverlayOnGame ? (
    <>
      {showLoadingBarResults ? <div className={styles.overlayGame}><LoadAnimation /></div> : null}
      {showMetricOverlay ? (
        <div className={styles.overlayGame}>
          <GameMetricsDashboard
            game={game}
            votes={userVotes}
            userTime={time}
            onClose={() => {
              setShowMetricOverlay(false);
            }}
          />
        </div>
      ) : null}
    </>
  ) : null;

  if (!isGameFinished) {
    // only render game player when game hasnt been finished, otherwise screen will be scrollable
    return (
      <GamePlayer
        game={game}
        updateUserVotes={updateUserVotes}
        userVotes={userVotes}
        time={time}
        onGameStart={() => { setClockStarted(true); }}
      />
    );
  }
  if (showOverlayOnGame) {
    return (
      <>
        <div className={styles.overlayBackdrop} />
        {overlay}
      </>
    );
  }

  return (
    <GameScoreBoard
      listOfImages={listOfImages}
      predictionsSpecialist={predictionsSpecialist}
      predictionsSpecialistAI={predictionsSpecialistAI}
      annotations={annotations}
      userVotes={Array.from(userVotes.values())}
    />
  );
};
export default GameController;
