import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import NavBar from '../navBar/NavBar';
import FooterNav from '../footerNav/FooterNav';
import MobileModal from '../mobileModal/MobileModal';
import LangToggle from '../langToggle/LangToggle';
import { ButtonPurpleOnWhite } from '../customButtons/CustomButtons';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { getUser } from '../../reducer/userReducer';

import styles from './AppPage.module.css';

const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [commonT] = useTranslation('common');

  const userStatus = useAppSelector((state) => state.user.status);

  /* triggers user update */
  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <div className={styles.profile}>
      <ButtonPurpleOnWhite
        onClick={() => {
          navigate(userStatus === 'loggedIn' ? '/oidc/logout' : '/oidc/authenticate');
          window.location.reload();
        }}
        className={styles.logoutButton}
      >
        {userStatus === 'loggedIn' && commonT('log-out')}
        {userStatus === 'loggedOut' && commonT('log-in')}
        {userStatus === 'progress' && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </ButtonPurpleOnWhite>
    </div>
  );
};

const Header: React.FC<{ children?: React.ReactNode; }> = (
  {
    children,
  },
) => (
  <div className={styles.header}>
    {children}
  </div>
);

Header.defaultProps = {
  children: false,
};

const Footer: React.FC<{ children?: React.ReactNode; }> = (
  {
    children,
  },
) => (
  <div className={styles.footer}>
    {children}
  </div>
);

Footer.defaultProps = {
  children: false,
};

interface AppPageProps {
  fixedHeight?: boolean
}

const AppPage: React.FC<AppPageProps> = ({ fixedHeight }) => (
  <div className={fixedHeight ? styles.bodyNoScroll : styles.body}>
    <Header>
      <NavBar />
      <LangToggle />
      <Profile />
    </Header>
    <div className={fixedHeight ? styles.contentContainerNoScroll : styles.contentContainer}>
      <Outlet />
    </div>
    <Footer>
      <FooterNav />
    </Footer>
    <MobileModal />
  </div>
);

AppPage.defaultProps = {
  fixedHeight: false,
};

export default AppPage;
