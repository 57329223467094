import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Row, Col, Container, OverlayTrigger, Button,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import Image from 'react-bootstrap/Image';
import PneumoAI_Video1 from '../../res/intro_videos/video1_720p_3mbps.mp4';
import PneumoAI_Video2 from '../../res/intro_videos/video2_720p_3mbps.mp4';
import PneumoAI_Video3 from '../../res/intro_videos/video3_720p_3mbps.mp4';

import PneumoAI_Thumb1 from '../../res/intro_videos/Thumbnail_1.png';
import PneumoAI_Thumb2 from '../../res/intro_videos/Thumbnail_2.png';
import PneumoAI_Thumb3 from '../../res/intro_videos/Thumbnail_3.png';

import healthy from '../../res/healthy.jpeg';
import pneumonia1 from '../../res/pneumonia1.jpeg';
import pneumonia2 from '../../res/pneumonia2.jpeg';

import VideoElement from '../../features/videoElement/VideoElement';
import { s } from '../../app/helper';

import styles from './Introduction.module.css';
import fontStyles from '../../features/text/Text.module.css';

import { ReactComponent as Roboter } from '../../res/Roboter.svg';
import { ButtonPurpleOnWhite } from '../../features/customButtons/CustomButtons';

const ExampleCard: React.FC<{
  image: string;
  annotation: boolean;
}> = ({ annotation, image }) => {
  const [t] = useTranslation('game_score_board');
  return (
    <div className={styles.exampleCard}>
      <div className={styles.imageFrame}>
        <Image
          fluid
          src={image}
          className={`${styles.mainImage} ${annotation ? styles.badBorder : styles.goodBorder} `}
        />
      </div>
      <div className={styles.imageLabel}>
        <FontAwesomeIcon
          icon={faCircle}
          className={`${styles.bullet} ${annotation ? styles.badColor : styles.goodColor}`}
        />
        &nbsp;&nbsp;
        <span>{annotation ? t('pneumonia') : t('normal')}</span>
      </div>
    </div>
  );
};

const Introduction: React.FC = () => {
  const navigate = useNavigate();
  const [commonT] = useTranslation('common');
  const [t] = useTranslation('introduction');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  return (
    <Container fluid className={styles.introductionContainer}>
      <Row className={styles.Row}>
        <Col lg={8} md={12} className={fontStyles.HeaderPages}>
          {t('title')}
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col lg={8} xs={12} className={fontStyles.text}>
          {t('description')}
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col lg={2} xs={12} className={s(fontStyles.button, styles.flexedColumn)}>
          <ButtonPurpleOnWhite
            size="lg"
            onClick={() => {
              navigate('/play');
            }}
            className={styles.moreInfoButton}
          >
            {commonT('start')}
          </ButtonPurpleOnWhite>
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col lg={8} xs={12} className={fontStyles.SubHeaderPages}>
          {t('how-functions')}
        </Col>
      </Row>
      <Row className={styles.videoSection}>
        <Col xs={12} lg={3}>
          <VideoElement
            video_thumb={PneumoAI_Thumb1}
            video_url={PneumoAI_Video1}
            title={t('video1-title')}
            description={t('video1-description')}
          />
          <OverlayTrigger
            trigger={['click']}
            placement="right"
            show={showMoreInfo}
            delay={{ show: 100, hide: 0 }}
            overlay={(
              <Container className={styles.moreInfoOverlay}>
                <Row className={styles.overlayHeader}>
                  <Button
                    onClick={() => setShowMoreInfo(false)}
                    variant="link"
                    className={styles.closeOverlayButton}
                  >
                    <FontAwesomeIcon icon={faTimes} size="2x" />
                  </Button>
                </Row>
                <Row className={styles.overlayContent}>
                  {/* <div className={s(styles.overlayTitle, fontStyles.SubHeaderPopups)}> */}
                  {/*  Lorem Ipsum */}
                  {/* </div> */}
                  <div className={s(fontStyles.HeaderPopups)}>
                    {commonT('examples')}
                  </div>
                  <div className={s(styles.overlayDescription, fontStyles.info)}>
                    {t('popup-text')}
                  </div>
                  <div className={styles.overlayDescriptionCards}>
                    <ExampleCard image={pneumonia1} annotation />
                    <ExampleCard image={pneumonia2} annotation />
                    <ExampleCard image={healthy} annotation={false} />
                  </div>
                </Row>
              </Container>
            )}
          >
            <div className={styles.moreInfoContainer}>
              <ButtonPurpleOnWhite
                onClick={() => setShowMoreInfo(!showMoreInfo)}
                className={styles.moreInfoButton}
              >
                {commonT('examples')}
              </ButtonPurpleOnWhite>
            </div>
          </OverlayTrigger>
        </Col>
        <Col xs={12} lg={3}>
          <VideoElement
            video_thumb={PneumoAI_Thumb2}
            video_url={PneumoAI_Video2}
            title={t('video2-title')}
            description={t('video2-description')}
          />
        </Col>
        <Col xs={12} lg={3}>
          <VideoElement
            video_thumb={PneumoAI_Thumb3}
            video_url={PneumoAI_Video3}
            title={t('video3-title')}
            description={t('video3-description')}
          />
        </Col>
        <Col
          lg={{ span: 1 }}
          xs={3}
          className={s(fontStyles.text, styles.flexedColumn, styles.robotColumn)}
        >
          <Roboter className={styles.robot} />
        </Col>
      </Row>
    </Container>
  );
};

export default Introduction;
