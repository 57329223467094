export const s = (...args: string[]): string => args.join(' ');

/**
 * wrapper class for storing api error response
 */
export class APIError extends Error {
  constructor(
    public status: number,
    public statusText: string,
    public details: Response,
  ) {
    super(`[${status}]: ${statusText}`);
  }

  public handled: boolean = false;
}

/**
 * Wrapper over {@link fetch} to handle api response errors.
 */
export const api: typeof fetch = async (...args) => {
  const [input, init] = args;
  const response = await fetch(input, { ...init/* , redirect: 'manual' */ });

  if (!response.ok) {
    throw new APIError(response.status, response.statusText, response);
  }

  return response;
};

export const replacer = (key: string, value: any): any => {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()),
    };
  }
  return value;
};

export const reviver = (key: string, value: any): any => {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
};
