import React from 'react';
import {
  Container, Row, Col, ProgressBar,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Clock from '../clock/Clock';
import { NestedGame } from '../../app/datatypes';
import { s } from '../../app/helper';
import { ButtonPurpleOnWhite } from '../customButtons/CustomButtons';

import styles from './GameMetricsDashboard.module.css';
import fontStyles from '../text/Text.module.css';

interface GameMetricsDashboardProps {
  votes: Map<number, boolean | null>;
  game: NestedGame;
  userTime: number;
  onClose: () => void;
}

/**
 * renders the Metrics dashboard
 */
const GameMetricsDashboard: React.FC<GameMetricsDashboardProps> = ({
  game,
  votes,
  userTime,
  onClose,
}: GameMetricsDashboardProps): JSX.Element => {
  const [t] = useTranslation('game_metrics_dashboard');
  const [tcommon] = useTranslation('common');

  const navigate = useNavigate();

  const renderRow = (
    playerName: string,
    totalSamples: number,
    correctSamples: number,
    seconds: number,
  ): JSX.Element => {
    const percent = Math.floor((correctSamples * 100) / totalSamples);
    return (
      <Row className={styles.metricRow}>
        <Col xs={1}><Clock seconds={seconds} /></Col>
        <Col xs={4} className={styles.detailTypeText}>
          <h1 className={fontStyles.SubHeaderPages}>{playerName}</h1>
          <h4 className={fontStyles.text}>
            {`${correctSamples} ${t('out of')} ${totalSamples} ${t('cases detected')}`}
          </h4>
        </Col>
        <Col xs={7} className={styles.progressCol}>
          <div>
            <ProgressBar className={styles.progressBarParent}>
              <ProgressBar isChild className={styles.progressBar} now={percent} />
            </ProgressBar>
          </div>
          {percent}
          %
        </Col>
      </Row>
    );
  };

  const numRightPreditionsSpecialist = game.samples.filter((smp) => smp.annotation === smp.pred_specialist).length;
  const numRightPreditionsSpecialistAi = game.samples.filter((smp) => smp.annotation === smp.pred_specialist_ai).length;
  const numRightPreditionsUser = game.samples.filter((smp, inx) => smp.annotation === votes.get(inx)).length;
  const numSamples = game.samples.length;
  return (
    <Container className={styles.container}>
      <p className={s(fontStyles.HeaderPages, styles.title)}>{t('header')}</p>
      <p className={s(fontStyles.text, styles.caption)}>
        {t('caption')}
      </p>
      {renderRow(tcommon('your-choice'), numSamples, numRightPreditionsUser, userTime)}
      {renderRow(tcommon('specialist'), numSamples, numRightPreditionsSpecialist, game.time_specialist)}
      {renderRow(tcommon('specialist-ai'), numSamples, numRightPreditionsSpecialistAi, game.time_specialist_ai)}
      <Row>
        <Col className={styles.centerCol}>
          <ButtonPurpleOnWhite
            className={styles.closeButton}
            onClick={onClose}
          >
            {t('detailedView')}
          </ButtonPurpleOnWhite>
          <ButtonPurpleOnWhite
            className={styles.closeButton}
            onClick={() => {
              // start a new game
              navigate('/play');
            }}
          >
            <FontAwesomeIcon icon={faRotateRight} />
            <span className={styles.newButtonText}>
              {t('start-new-game')}
            </span>
          </ButtonPurpleOnWhite>
        </Col>
      </Row>
    </Container>
  );
};

export default GameMetricsDashboard;
