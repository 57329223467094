import React, { useEffect } from 'react';
import {
  Image,
  Spinner,
  Col,
  Card,
  Row,
} from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition, faCircleExclamation, faDice, faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import styles from './Selector.module.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getGames } from '../../reducer/gameListReducer';
import { reviver, s } from '../../app/helper';
import fontStyles from '../../features/text/Text.module.css';

import { ReactComponent as DetailsIcon } from '../../res/detailsicon.svg';

import { ButtonPurpleOnWhite } from '../../features/customButtons/CustomButtons';

type GameButtonProps = {
  faIcon?: IconDefinition,
  SvgIcon?: React.FC<React.SVGProps<SVGSVGElement>>,
  text: string,
  onClick: () => void
};

const GameButton: React.FC<GameButtonProps> = ({
  faIcon,
  SvgIcon,
  text,
  onClick,
}) => (
  <ButtonPurpleOnWhite
    className={styles.gameCardButton}
    onClick={() => {
      onClick();
    }}
  >
    {SvgIcon !== undefined && <SvgIcon className={styles.gameButtonSvg} />}
    {(faIcon !== undefined && (
    <FontAwesomeIcon
      className={styles.faIcon}
      icon={faIcon}
      size="1x"
    />
    ))}
    <span className={styles.gameButtonText}>{text}</span>

  </ButtonPurpleOnWhite>
);

GameButton.defaultProps = {
  faIcon: undefined,
  SvgIcon: undefined,
};

type GameCardProps = {
  gameNumber: number;
  src: string;
  onClick: () => void;
  isComplete?: boolean;
  percentDone: number
  playedBefore: boolean
  deleteGame: () => void;
};

const GameCard: React.FC<GameCardProps> = ({
  gameNumber,
  src,
  onClick,
  percentDone,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  playedBefore,
  deleteGame,
  isComplete,
}) => {
  const [t] = useTranslation('selector');

  let showPercent: string;
  if (percentDone === 0) {
    showPercent = t('open');
  } else if (percentDone === 100) {
    showPercent = t('closed');
  } else {
    showPercent = `${percentDone}%`;
  }

  return (
    <Col className={styles.gameCardContainer}>
      <Card className={styles.gameCard}>
        <div className={s(styles.gameCardHeader, fontStyles.text)}>
          <span>{`${t('game-round')} ${gameNumber}`}</span>
          <span>{showPercent}</span>
        </div>
        <Image src={src} className={styles.gameCardImage} />
        <div className={styles.gameCardButtonContainer}>
          {isComplete && (
            <>
              <GameButton
                SvgIcon={DetailsIcon}
                text={t('see-results')}
                onClick={() => {
                  if (onClick !== undefined) {
                    onClick();
                  }
                }}
              />
              <GameButton
                faIcon={faRotateRight}
                text={t('play-again')}
                onClick={() => {
                  if (onClick !== undefined) {
                    deleteGame();
                    onClick();
                  }
                }}
              />
            </>
          )}
          {!isComplete && (
            <GameButton
              faIcon={faDice}
              text={t('play')}
              onClick={() => {
                if (onClick !== undefined) {
                  onClick();
                }
              }}
            />
          )}
        </div>
      </Card>
    </Col>
  );
};

GameCard.defaultProps = {
  isComplete: false,
};

type SelectorProps = {};

const Selector: React.FC<SelectorProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation('selector');

  const games = useAppSelector((state) => state.gameList.games);
  const gameListStatus = useAppSelector((state) => state.gameList.status);

  useEffect(() => {
    dispatch(getGames());
  }, []);

  useEffect(() => {
    if (gameListStatus === 'success') {
      const nonePlayed = games.every((g) => localStorage.getItem(`played-${g.id}`) === null
        && localStorage.getItem(g.id) === null);

      if (nonePlayed && games.length > 0) {
        navigate(`/play/${games[0]?.id}`);
      }
    }
  }, [gameListStatus]);

  const deleteGameData = (gameId: string): void => {
    localStorage.removeItem(gameId);
    localStorage.removeItem(`${gameId}-time`);
    // stores separately a flag to indicate this game was once played. assumes game was played
    localStorage.setItem(`played-${gameId}`, 'true');
  };

  return (
    <div className={styles.selector}>
      <div className={styles.gameDetails}>
        <div className={s(styles.gameTitle, fontStyles.HeaderPages)}>
          {t('select-title')}
        </div>
        <Col lg={8} xs={12} className={s(fontStyles.text)}>
          {t('select-desc')}
        </Col>
      </div>
      {gameListStatus === 'loading' && (
        <div className={styles.gameListLoadingError}>
          <Spinner animation="border" />
        </div>
      )}
      {gameListStatus === 'error' && (
        <div className={styles.gameListLoadingError}>
          <FontAwesomeIcon icon={faCircleExclamation} size="3x" />
          &nbsp;&nbsp;&nbsp;
          {t('error-loading-list')}
        </div>
      )}
      {gameListStatus === 'success' && (
        <Row className={`${styles.gameCardsRow}`} xs={1} md={2} lg={4} xl={6} xxl={12}>
          {games?.reduce((shownGames, currentGame, gameInx) => {
            const voteData = localStorage.getItem(currentGame.id);
            const votes = voteData ? JSON.parse(voteData, reviver) as { votes: Map<number, boolean | null> } : null;
            let isComplete: boolean;

            if (!votes?.votes || Array.from(votes.votes.values()).includes(null)) {
              isComplete = false;
            } else {
              isComplete = true;
            }
            let percentDone = 0.0;
            if (votes?.votes) {
              const votesArray = Array.from(votes.votes.values());
              percentDone = Math.round((votesArray.filter((v) => v !== null).length / votesArray.length) * 100);
            }

            let playedBefore = false;
            // check if game was completed before
            if (localStorage.getItem(`played-${currentGame.id}`) !== null) {
              playedBefore = true;
            }

            return [...shownGames, <GameCard
              gameNumber={gameInx + 1}
              playedBefore={playedBefore}
              percentDone={percentDone}
              deleteGame={() => deleteGameData(currentGame.id)}
              key={`game-${currentGame.id}`}
              src={currentGame.preview.img}
              onClick={() => {
                navigate(`/play/${currentGame.id}`);
              }}
              isComplete={isComplete}
            />];
          }, [] as JSX.Element[])}
        </Row>
      )}
    </div>
  );
};

export default Selector;
