import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { languageDisplayNames } from '../../i18n/config';
import styles from './LangToggle.module.css';
import { s } from '../../app/helper';

type LangToggleProps = {
  center?: boolean;
  dropdown?: boolean;
};

const LangToggle: React.FC<LangToggleProps> = (
  {
    center,
    dropdown,
  },
) => {
  const [t, i18n] = useTranslation('language_selector');
  return (
    <div className={s(styles.langToggle, center ? styles.langToggleCenter : '')}>
      <span className={styles.langLabel}>{t('lang-prompt')}</span>
      <div className={styles.langButton}>
        {dropdown ? (
          <DropdownButton
            variant="outline-light"
            className={styles.langDropdownButton}
            title={(
              <span style={{ whiteSpace: 'nowrap' }}>
                {t('lang-button-label')}
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            )}
          >
            {Array.from(languageDisplayNames.keys()).map((l) => (
              <Dropdown.Item
                key={`lang-${l}`}
                active={i18n.language === l}
                onClick={async () => {
                  if (l === i18n.language) return;
                  await i18n.changeLanguage(l);
                }}
              >
                {`${languageDisplayNames.get(l)}\t(${l.toUpperCase()})`}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        ) : (
          <Button
            variant="outline-light"
            className={styles.langDropdownButton}
            onClick={async () => {
              await i18n.changeLanguage(Array.from(languageDisplayNames.keys()).find((l) => l !== i18n.language));
            }}
          >
            <span style={{ whiteSpace: 'nowrap' }}>
              {t('lang-button-label')}
              &nbsp;&nbsp;
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};

LangToggle.defaultProps = {
  center: true,
  dropdown: false,
};

export default LangToggle;
