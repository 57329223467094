import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import licensesFrontend from '../../res/licensesFrontend.json';
import licensesBackend from '../../res/licensesBackend.json';
import { s } from '../../app/helper';

import styles from './Oss.module.css';
import fontStyles from '../../features/text/Text.module.css';

/*
 * Renders Page with open source licenses
*/

const Oss: React.FC = () => {
  const [t] = useTranslation('common');
  const licenseToBeDisplayed = (component: string, license: string, url: string): JSX.Element => (
    <Card className={styles.license}>
      <Card.Body className={s(styles.licenseBody, fontStyles.text)}>
        <h5 className={styles.component}>{component}</h5>
        <h5>
          <Badge bg="secondary">{license}</Badge>
        </h5>
        <a href={url}>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </Card.Body>
    </Card>
  );
  return (
    <div className={styles.body}>
      <div className={s(styles.detailsHeader, fontStyles.HeaderPages)}>
        {t('open-source-licenses')}
      </div>
      {licensesFrontend.map((e) => licenseToBeDisplayed(e.component, e.license, e.url))}
      {licensesBackend.map((e) => licenseToBeDisplayed(e.component, e.license, e.url))}
    </div>
  );
};
export default Oss;
