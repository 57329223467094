import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getUser } from '../../reducer/userReducer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

const AuthRoute: React.FC<{ children?: React.ReactNode; }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (user.status === 'loggedIn') return;

    // verify login cookies
    dispatch(getUser());
  }, []);

  useEffect(() => {
    if (user.status === 'refresh') {
      navigate('/oidc/authenticate');
      window.location.reload();
    }
  }, [user.status]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return user.status !== 'loggedOut' ? <>{children}</> : <Navigate to="/" replace />;
};

AuthRoute.defaultProps = {
  children: false,
};

export default AuthRoute;
