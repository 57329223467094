/* eslint-disable no-param-reassign,@typescript-eslint/no-use-before-define */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, APIError } from '../app/helper';

export interface UserState {
  status: 'loggedIn' | 'loggedOut' | 'progress' | 'verify' | 'refresh';
  user?: User;
  error?: string;
}

type User = {
  id: string;
  full_name: string;
};

const initialState = {
  status: 'verify',
  user: undefined,
  error: undefined,
} as UserState;

export const getUser = createAsyncThunk<User>(
  'user/get',
  (_, thunkAPI) => api('/api/user')
    .then((r) => r.json())
    .then((r) => r as User)
    .catch((err) => {
      if (err instanceof APIError) {
        const error = err as APIError;

        if (error.status === 401) {
          thunkAPI.dispatch(logOut());
        }

        const redirected = (error.details.status > 300 && error.details.status < 400)
          || error.details.type === 'opaqueredirect';

        if (!error.details || redirected || (error.details.redirected
          && error.details.headers.get('Content-Type')?.includes('html'))) {
          thunkAPI.dispatch(needsRefresh());
        }
        err.handled = true;
      }
      throw err;
    }),
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logOut: () => ({ ...initialState }),
    needsRefresh: () => ({ ...initialState, status: 'refresh' } as UserState),
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.status = 'progress';
    }).addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.error = '';
      state.status = 'loggedIn';
    }).addCase(getUser.rejected, (state, action) => {
      if (action.error instanceof APIError && action.error.handled) {
        return;
      }

      state.error = action.error?.message || 'unknown error';
      state.status = 'loggedOut';
      state.user = undefined;
    });
  },
});

export const {
  logOut,
  needsRefresh,
} = userSlice.actions;

export default userSlice.reducer;
