import React, { useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { s } from '../../app/helper';
import styles from './VideoElement.module.css';
import fontStyles from '../text/Text.module.css';

type VideoElementProps = {
  video_url: string;
  video_thumb: string;
  title: string;
  description: string;
};

const VideoElement: React.FC<VideoElementProps> = ({
  video_url,
  video_thumb,
  title,
  description,
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const [, i18n] = useTranslation('common');
  return (
    <div className={styles.videoElement}>
      <div className={styles.videoImageContainer}>
        <Image
          className={styles.videoImage}
          src={video_thumb}
        />
        <div
          role="button"
          tabIndex={0}
          className={styles.videoImageOverlay}
          onClick={() => setShowVideo(true)}
          aria-hidden
        >
          <FontAwesomeIcon
            icon={faPlay}
            size="5x"
            className={styles.videoPlayIcon}
          />
        </div>
      </div>
      <div className={styles.videoDetails}>
        <div className={s(styles.videoTitle, fontStyles.SubHeaderPages)}>
          {title}
        </div>
        <div lang={i18n.language} className={s(fontStyles.text, styles.videoDescription)}>
          {description}
        </div>
      </div>
      <Modal
        show={showVideo}
        size="xl"
        centered
        onHide={() => setShowVideo(false)}
        className={styles.videoModal}
      >
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video width="100%" controls playsInline>
          <source type="video/mp4" src={video_url} />
        </video>
      </Modal>
    </div>
  );
};

export default VideoElement;
