import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { s } from '../../app/helper';
import styles from './LimitationsAcknowledgement.module.css';
import fontStyles from '../../features/text/Text.module.css';

/*
 * Renders Page with open source licenses
*/

const LimitationsAcknowledgement: React.FC = () => {
  const link = <a href="https://ieeexplore.ieee.org/abstract/document/10022222">Link</a>;
  const [tLimit] = useTranslation('disclosures');
  return (
    <div className={styles.body}>
      <div className={s(fontStyles.SubHeaderPages, styles.paragraph)}>
        {tLimit('disclosures-heading')}
      </div>
      <div className={s(fontStyles.text, styles.paragraph)}>
        <Trans t={tLimit} i18nKey="disclosures" components={[link]} />
      </div>
      <div className={s(fontStyles.SubHeaderPages, styles.paragraph)}>
        {tLimit('acknowledgement-heading')}
      </div>
      <div className={s(fontStyles.text, styles.paragraph)}>
        Kermany, Daniel; Zhang, Kang; Goldbaum, Michael (2018), “Labeled Optical
        Coherence Tomography (OCT) and Chest X-Ray Images for Classification”,
        Mendeley Data, v2
        <a className={styles.link} href="http://dx.doi.org/10.17632/rscbjbr9sj.2">
          {'Link '}
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </div>
    </div>
  );
};
export default LimitationsAcknowledgement;
