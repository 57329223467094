import impressumMD from './Impressum.md';
import datenschutzMD from './Datenschutz.md';
import nutzungsbedingungenMD from './Nutzungsbedingungen.md';

export { default as common } from './common.json';
export { default as language_selector } from './language_selector.json';
export { default as selector } from './selector.json';
export { default as navbar } from './navbar.json';
export { default as explain } from './explain.json';
export { default as introduction } from './introduction.json';
export { default as landing } from './landing.json';
export { default as game_player } from './game_player.json';
export { default as game_metrics_dashboard } from './game_metrics_dashboard.json';
export { default as game_score_board } from './game_score_board.json';
export { default as disclosures } from './disclosures.json';

export const impressum = { url: impressumMD };
export const datenschutz = { url: datenschutzMD };
export const nutzungsbedingungen = { url: nutzungsbedingungenMD };
