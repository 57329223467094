import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Game, Sample } from '../app/datatypes';
import { api, APIError } from '../app/helper';
import { handleAuthError } from './actionHelper';

export type GameWithPreview = {
  preview: Sample;
} & Game;

export interface GameListState {
  status: 'idle' | 'success' | 'loading' | 'error';
  games: GameWithPreview[];
  error: string;
}

const initialState = {
  status: 'idle',
  games: [],
  error: '',
} as GameListState;

/**
 * Fetches all available games from the REST API
 */
export const getGames = createAsyncThunk<GameWithPreview[]>('gameList/get', async (_, thunkAPI) => {
  // fetch a list of all games with their ids and names
  const games = await api('/api/game/')
    .then((r) => r.json())
    .then((r) => r as Game[])
    .catch((err) => {
      if (err instanceof APIError) {
        handleAuthError(err as APIError, thunkAPI);
      }
      throw err;
    });

  // fetch one preview for each game with sample id 0
  const previews = await Promise.all(games.map((g) => {
    const sampleQuery = new URLSearchParams({
      game_id: `${g.id}`,
      sample_id: '0',
    });
    return api(`/api/sample/?${sampleQuery.toString()}`)
      .then((r) => r.json())
      .then((r) => r[0] as Sample)
      .catch((err) => {
        if (err instanceof APIError) {
          handleAuthError(err as APIError, thunkAPI);
        }
        throw err;
      });
  }));

  return games.map((g, i) => ({ ...g, preview: previews[i] } as GameWithPreview)).sort(
    (a, b) => a.id.localeCompare(b.id),
  );
});

export const gameListSlice = createSlice({
  name: 'gameList',
  initialState,
  reducers: {
    clear: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(getGames.pending, (state) => {
      state.status = 'loading';
    }).addCase(getGames.fulfilled, (state, action) => {
      state.games = action.payload;
      state.error = '';
      state.status = 'success';
    }).addCase(getGames.rejected, (state, action) => {
      if (action.error instanceof APIError && action.error.handled) return;

      state.error = action.error?.message || 'unknown error';
      state.status = 'error';
    });
  },
});

export const {
  clear,
} = gameListSlice.actions;

export default gameListSlice.reducer;
