import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import './CustomButtons.css';

type CustomButtonType = Omit<ButtonProps, 'variant'>;

export const ButtonPurpleOnWhite: React.FC<CustomButtonType> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Button variant="purpleOnWhite" {...props} />
);
