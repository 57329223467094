import React from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';

import AppPage from './features/appPage/AppPage';
import GameScreen from './pages/GameScreen';
import Selector from './pages/selector/Selector';
import LandingLoginPage from './pages/landingLoginPage/LandingLoginPage';
import Explain from './pages/explain/Explain';
import Introduction from './pages/introduction/Introduction';
import Oss from './pages/oss/Oss';
import Datenschutz from './pages/datenschutz/Datenschutz';
import Impressum from './pages/impressum/Impressum';
import Nutzungsbedingungen from './pages/nutzungsbedingungen/Nutzungsbedingungen';
import LimitationsAcknowledgement from './pages/limitationsAcknowledgement/LimitationsAcknowledgement';

import { s } from './app/helper';

import appStyles from './css/App.module.css';
import AuthRoute from './features/authRoute/AuthRoute';

const App: React.FC = () => (
  <div data-testid="app-main-element" className={s(appStyles.mainContainer, appStyles.App)}>
    <Routes>
      <Route path="/oss" element={<AuthRoute><AppPage /></AuthRoute>}>
        <Route
          path="/oss"
          element={<Oss />}
        />
      </Route>
      <Route path="/play/:gameId" element={<AuthRoute><AppPage /></AuthRoute>}>
        <Route
          path="/play/:gameId"
          element={<GameScreen />}
        />
      </Route>
      <Route path="/play" element={<AuthRoute><AppPage /></AuthRoute>}>
        <Route
          path="/play"
          element={<Selector />}
        />
      </Route>
      <Route path="/explain" element={<AppPage />}>
        <Route
          path="/explain"
          element={<Explain />}
        />
      </Route>
      <Route path="/introduction" element={<AppPage />}>
        <Route
          path="/introduction"
          element={<Introduction />}
        />
      </Route>
      <Route path="/imprint" element={<AppPage />}>
        <Route
          path="/imprint"
          element={<Impressum />}
        />
      </Route>
      <Route path="/dataprotection" element={<AppPage />}>
        <Route
          path="/dataprotection"
          element={<Datenschutz />}
        />
      </Route>
      <Route path="/limitations" element={<AuthRoute><AppPage /></AuthRoute>}>
        <Route
          path="/limitations"
          element={<LimitationsAcknowledgement />}
        />
      </Route>
      <Route path="/terms-of-use" element={<AppPage />}>
        <Route
          path="/terms-of-use"
          element={<Nutzungsbedingungen />}
        />
      </Route>
      <Route
        path="/"
        element={<LandingLoginPage />}
      />
    </Routes>
  </div>
);

export default App;
