import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

import ExplainCarousel from '../../features/explainCarousel/ExplainCarousel';

import styles from './Explain.module.css';
// import { s } from '../../app/helper';
import fontStyles from '../../features/text/Text.module.css';

import { ReactComponent as Roboter } from '../../res/Roboter.svg';
import { ButtonPurpleOnWhite } from '../../features/customButtons/CustomButtons';

const Explain: React.FC = () => {
  const navigate = useNavigate();
  const [commonT] = useTranslation('common');
  const [t] = useTranslation('explain');

  return (
    <Container fluid className={styles.explainContainer}>
      <Row className={styles.Row}>
        <Col lg={{ span: 10, order: 0 }} xs={12} className={fontStyles.HeaderPages}>
          {t('title')}
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col lg={8} xs={12} className={fontStyles.text}>
          {t('description')}
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col lg={8} xs={12} className={fontStyles.text}>
          {t('secondDescription')}
        </Col>
      </Row>
      <ExplainCarousel />
      <ButtonPurpleOnWhite
        onClick={() => {
          navigate(-1);
        }}
        className={styles.backButton}
      >
        {commonT('back-button')}
      </ButtonPurpleOnWhite>
      <Roboter className={styles.robot} />
    </Container>
  );
};

export default Explain;
