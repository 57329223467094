import React from 'react';
import {
  Nav, Navbar,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';
import { s } from '../../app/helper';

import safetyPdf from '../../res/IAIS_Sicherheitskonzept-2020-final.pdf';

import styles from './FooterNav.module.css';
import fontStyles from '../text/Text.module.css';
import { useAppSelector } from '../../app/hooks';

const FooterNav: React.FC = () => {
  const [t] = useTranslation('common');
  const location = useLocation();

  const userStatus = useAppSelector((state) => state.user.status);

  return (
    <Navbar bg="transparent" variant="dark" className={s(styles.footerNav, fontStyles.footer)}>
      <span className={styles.footerCopyrightNotice}>
        © Kompetenzplattform KI.NRW 2023
      </span>
      <Nav activeKey={location.pathname}>
        <LinkContainer
          to="/imprint"
          className={styles.footerNavLink}
        >
          <Nav.Link>{t('impressum')}</Nav.Link>
        </LinkContainer>
        <LinkContainer
          to="/dataprotection"
          className={styles.footerNavLink}
        >
          <Nav.Link>{t('data-protection')}</Nav.Link>
        </LinkContainer>
        <LinkContainer
          to="/terms-of-use"
          className={styles.footerNavLink}
        >
          <Nav.Link>{t('nutzungsbedingungen')}</Nav.Link>
        </LinkContainer>
        {userStatus === 'loggedIn' && (
          <>
            <LinkContainer
              to="/oss"
              className={styles.footerNavLink}
            >
              <Nav.Link>{t('open-source-licenses')}</Nav.Link>
            </LinkContainer>
            <LinkContainer
              to="/limitations"
              className={styles.footerNavLink}
            >
              <Nav.Link>{t('limitation-and-acknowledgement')}</Nav.Link>
            </LinkContainer>
            <LinkContainer
              to="/"
              onClick={(e) => {
                e.preventDefault();
                window.open(safetyPdf, '_blank', 'noopener noreferrer');
              }}
              className={styles.footerNavLink}
            >
              <Nav.Link>{t('safety')}</Nav.Link>
            </LinkContainer>
          </>
        )}
      </Nav>
    </Navbar>
  );
};

export default FooterNav;
