import React from 'react';
import { s } from '../../app/helper';

import { ReactComponent as NavigationArrow } from '../../res/NavigationArrow.svg';

import styles from './NavArrow.module.css';
import fontStyles from '../text/Text.module.css';

type NavArrowProps = {
  enabled?: boolean;
  onClick: () => void;
  flipped?: boolean;
  title?: string;
};

const NavArrow: React.FC<NavArrowProps> = ({
  enabled,
  onClick,
  flipped,
  title,
}) => (
  <div className={styles.navArrowContainer}>
    <NavigationArrow
      className={s(styles.navArrow, flipped ? styles.flippedArrow : '', enabled ? '' : styles.navArrowDisabled)}
      onClick={() => {
        if (!enabled) {
          return;
        }
        onClick();
      }}
    />
    <span className={s(styles.arrowTitle, fontStyles.smallButton, enabled ? '' : styles.navArrowDisabled)}>
      {title}
    </span>
  </div>
);

NavArrow.defaultProps = {
  enabled: true,
  flipped: false,
  title: '',
};

export default NavArrow;
