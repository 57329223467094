/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row, Col, Image,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import VisibilitySensor from 'react-visibility-sensor';
import Carousel from 'react-slick';
import { NestedGame } from '../../app/datatypes';
import { s } from '../../app/helper';

import Clock, { Variant as ClockVariant } from '../clock/Clock';
import NavArrow from '../navArrow/NavArrow';
import { ReactComponent as Roboter } from '../../res/Roboter.svg';
import pneumonia1 from '../../res/pneumonia1.jpeg';
import healthy from '../../res/healthy.jpeg';

import styles from './GamePlayer.module.css';
import fontStyles from '../text/Text.module.css';

/**
 * interface of Game Player
 */
interface GamePlayerProps {
  game: NestedGame,
  userVotes: Map<number, boolean | null>,
  time: number;
  onGameStart: () => void,

  updateUserVotes(userVotes: Map<number, boolean | null>): void
}

/**
 * gets the index of the next sample which has no vote. If it does not exist returns the current index
 * @param currentIndex the index of the image currently being displayed.
 * @param voteArraylue
 * @param voteLength
 */
const getNextSampleWithoutVote = (
  currentIndex: number,
  voteArray: (boolean | null | undefined)[],
  voteLength: number,
): number => {
  // first search in images with index >= the current one
  let nullIndex = voteArray.slice(currentIndex, voteLength).findIndex((i) => i === null);
  if (nullIndex !== -1) {
    // found a sample without vote
    // adjusts shift imposed by slice
    nullIndex += currentIndex;
  } else {
    // check previous images
    nullIndex = voteArray.findIndex((i) => i === null);
  }

  if (nullIndex !== -1) {
    // found a sample which has not been voted in a subsequent sample
    return nullIndex;
  }
  return currentIndex;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExampleCard: React.FC<{
  image: string;
  isNormal: boolean;
}> = ({ isNormal, image }) => {
  const [t] = useTranslation('game_player');
  return (
    <Col xs={6} className={styles.exampleCard}>
      <div className={styles.imageFrame}>
        <Image
          fluid
          src={image}
          className={`${styles.mainImage} ${isNormal ? styles.goodBorder : styles.badBorder} `}
        />
      </div>
      <div className={styles.imageLabel}>
        <div
          className={s(
            isNormal ? styles.noButton : styles.yesButton,
            styles.voteButton,
          )}
        >
          {isNormal ? t('no_vote') : t('yes_vote')}
        </div>
      </div>
    </Col>
  );
};

/**
 * The GamePlayer component is a reimplement version of the interface for playing games
 * render GameController
 * @param game
 * @param userVotes
 * @param time
 * @param onGameStart
 * @param updateUserVotes
 */

const GamePlayer: React.FC<GamePlayerProps> = (
  {
    game,
    userVotes,
    time,
    onGameStart,
    updateUserVotes,
  },
) => {
  const [t] = useTranslation('game_player');
  const [commonT] = useTranslation('common');

  const slideRef = useRef<Carousel>(null);

  const gameLength = game.samples?.length || 0;

  const voteArray = Array.from(Array(gameLength).keys()).map((sampleInx) => userVotes.get(sampleInx));

  const [currentImage, setCurrentImage] = useState(getNextSampleWithoutVote(0, voteArray, gameLength));

  // determines if a vote from the user is being processed (for the animations)
  const [voteInProgress, setVoteInProgress] = useState(false);

  const enableLeftArrow = currentImage > 0 && !voteInProgress;
  const enableRightArrow = currentImage < gameLength - 1 && !voteInProgress;

  const gamePlayerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    slideRef.current?.slickGoTo(currentImage);
  }, [currentImage]);

  // called when there is an update to uservotes
  useEffect(() => {
    setTimeout(() => {
      const nextInx = getNextSampleWithoutVote(currentImage, voteArray, gameLength);
      if (nextInx !== currentImage) {
        setCurrentImage(nextInx);
      }
    }, 100);
  }, [userVotes]);
  // should not depend on currentImageIndex bc we dont want this effect to be triggered whenever the image index changes

  return (
    <>
      <div className={styles.gameHeaderRowContainer}>
        <Row className={s(styles.Row)}>
          <Col sm={10} className={s('gx-0', styles.gameHeaderColumn)}>
            <div className={s(styles.gameHeader, fontStyles.HeaderPages)}>
              {t('header_text')}
            </div>
            <div className={s(styles.gameDescription, fontStyles.info)}>
              {t('description_text')}
              {' '}
              {t('tip_text')}
            </div>
          </Col>
          <Col sm={2} className={styles.gameHeaderColumn}>
            <Roboter className={styles.robot} />
          </Col>
        </Row>
        <Row className={styles.gameHeaderExamplesAndButtonRow}>
          <Col
            xs={8}
            lg={8}
            xl={6}
            className={s('gx-0', styles.exampleContainer)}
          >
            <ExampleCard image={pneumonia1} isNormal={false} />
            <ExampleCard image={healthy} isNormal />
          </Col>
          <Col lg={12} xl={{ span: 4, offset: 0 }} xxl={{ span: 3, offset: 0 }} className={styles.goToGameButtonColumn}>
            <div
              className={styles.goToGameButtonContainer}
              onClick={() => {
                gamePlayerRef.current?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              <FontAwesomeIcon className={styles.goToGameButtonIcon} icon={faAnglesDown} bounce size="2x" />
              <div
                className={s(fontStyles.HeaderPages, styles.goToGameText)}
              >
                {t('start-game')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row className={s(styles.game, styles.Row)} ref={gamePlayerRef}>
        <Col xs={1} className={styles.navContainerPrevious}>
          <NavArrow
            enabled={enableLeftArrow}
            title={commonT('previous_sample')}
            onClick={() => {
              if (currentImage > 0) {
                slideRef.current?.slickPrev();
              }
            }}
          />
        </Col>
        <Col xs={10} className={styles.gameCol}>
          <div className={styles.gameSliderContainer}>
            <Carousel
              ref={slideRef}
              centerMode
              centerPadding="0px"
              slidesToShow={3}
              speed={500}
              arrows={false}
              swipe={false}
              touchMove={false}
              infinite={false}
              className={styles.gameSlider}
              beforeChange={(oldI, newI) => {
                if (voteInProgress) return;

                if (newI > -1 && newI < gameLength) {
                  setCurrentImage(newI);
                }
              }}
              afterChange={() => {
                setVoteInProgress(false);
              }}
            >
              {
                game.samples?.map((sx, i) => (
                  <div
                    className={s(styles.slideImage, {
                      [(gameLength + currentImage - 2) % gameLength]: styles.previousTwo,
                      [(gameLength + currentImage - 1) % gameLength]: styles.previous,
                      [currentImage]: styles.current,
                      [(gameLength + currentImage + 1) % gameLength]: styles.next,
                      [(gameLength + currentImage + 2) % gameLength]: styles.nextTwo,
                    }[i] || '')}
                    key={`sample-${sx.id}`}
                  >
                    <Image
                      className={styles.slideImageInner}
                      src={sx.img}
                    />
                  </div>
                ))
              }
              <div />
              <div />
            </Carousel>
          </div>
          <VisibilitySensor
            onChange={(isVisible: boolean) => {
              if (isVisible) {
                onGameStart();
              }
            }}
          >
            <div className={styles.gameControlsRow}>
              <div
                role="button"
                tabIndex={-1}
                className={s(
                  styles.yesButton,
                  styles.voteButton,
                  userVotes.get(currentImage) === true ? styles.selectedButton : '',
                )}
                onClick={() => {
                  if (voteInProgress) {
                    return;
                  }
                  setVoteInProgress(true);

                  userVotes.set(currentImage, true);
                  updateUserVotes(new Map(userVotes));
                }}
              >
                {t('yes_vote')}
              </div>
              <div className={styles.clock}>
                <Clock seconds={time} variant={ClockVariant.purple} />
              </div>
              <div
                role="button"
                tabIndex={-1}
                className={s(
                  styles.noButton,
                  styles.voteButton,
                  userVotes.get(currentImage) === false ? styles.selectedButton : '',
                )}
                onClick={() => {
                  if (voteInProgress) {
                    return;
                  }
                  setVoteInProgress(true);

                  userVotes.set(currentImage, false);
                  updateUserVotes(new Map(userVotes));
                }}
              >
                {t('no_vote')}
              </div>
            </div>
          </VisibilitySensor>
        </Col>
        <Col xs={1} className={styles.navContainerNext}>
          <NavArrow
            flipped
            enabled={enableRightArrow}
            title={commonT('next_sample')}
            onClick={() => {
              if (currentImage < gameLength - 1) {
                slideRef.current?.slickNext();
              }
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default GamePlayer;
