import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NestedGame } from '../app/datatypes';
import { api, APIError } from '../app/helper';
import { handleAuthError } from './actionHelper';

export interface GameState {
  status: 'idle' | 'success' | 'loading' | 'error';
  game: NestedGame;
  error: string;
}

const initialState = {
  status: 'idle',
  game: {},
  error: '',
} as GameState;

/**
 * Fetches a single game from the REST API
 * @param game_id the id of the game
 */
export const getGame = createAsyncThunk<NestedGame, {
  game_id: string;
}>('game/get', ({ game_id }, thunkAPI) => {
  if (!game_id) {
    throw new Error('game id is undefined');
  }
  return api(`/api/game/nested/${game_id}`)
    .then((r) => r.json())
    .then((r) => r as NestedGame)
    .catch((err) => {
      if (err instanceof APIError) {
        handleAuthError(err as APIError, thunkAPI);
      }
      throw err;
    });
});

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    clear: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(getGame.pending, (state) => {
      state.status = 'loading';
    }).addCase(getGame.fulfilled, (state, action) => {
      state.game = action.payload;
      state.error = '';
      state.status = 'success';
    }).addCase(getGame.rejected, (state, action) => {
      if (action.error instanceof APIError && action.error.handled) return;

      state.error = action.error?.message || 'unknown error';
      state.status = 'error';
    });
  },
});

export const {
  clear,
} = gameSlice.actions;

export default gameSlice.reducer;
