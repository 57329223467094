import React from 'react';
import styles from './Clock.module.css';
import { s } from '../../app/helper';

export enum Variant {
  white,
  purple,
}

interface ClockProps {
  seconds: number,
  variant?: Variant,
}

/**
 * renders a clock
*/
const Clock = ({
  seconds, variant,
}: ClockProps): JSX.Element => {
  const minutes = Math.floor(seconds / 60);
  const secondsRest = seconds % 60;
  const timeStr = `${minutes.toString().padStart(2, '0')}:${secondsRest.toString().padStart(2, '0')}`;
  return (
    <span className={s(styles.clockCircle, variant === Variant.white ? styles.clockWhite : styles.clockPurple)}>
      {timeStr}
    </span>
  );
};

Clock.defaultProps = {
  variant: Variant.white,
};

export default Clock;
